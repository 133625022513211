import { createContext, useContext, useState, useEffect, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectConversation,
  getMessageList,
  getRoomList,
  appendMessageList,
  setRoomList,
  setMessageList,
} from '../../store/reducers/conversations';
import { GET, LOCAL, POST, apiGenerator } from '../../api/api-manager';
import { checkSession, selectAuth, sessionExpired } from '../../store/reducers/auth';
import { useSocket } from './SocketProvider';
import { useSearch } from './SearchContext';

const ConversationContext = createContext();

// Variables.
var timeTaken = null;
const limit = 20;

export function useConversation() {
  return useContext(ConversationContext);
}

export function ConversationProvider({ children, playNotificationSound }) {
  // * Custom hooks.
  const dispatch = useDispatch();
  const { socket } = useSocket();

  const conversation = useSelector(selectConversation);
  const authState = useSelector(selectAuth);
  const { debouncedChatSearchOptions } = useSearch();

  // * State & ref hooks.
  const filteredCustomerIds = useRef(undefined);

  const [customerName, setCustomerName] = useState(conversation.roomList[0]?.profile_name);
  const [customerPhone, setCustomerPhone] = useState(conversation.roomList[0]?.phone_number);
  const [customerID, setCustomerID] = useState(conversation.roomList[0]?._id);
  const [agentList, setAgentList] = useState([]);
  const [agentGroupList, setAgentGroupList] = useState([]);

  const [messageLimit, setMessageLimit] = useState(limit);

  // * Helper functions.
  const getMessageLimit = () => {
    return limit;
  };

  const updateWindowChat = (customer_id, customerName, customerPhone, limit = 20) => {
    console.log(`updateWindowChat...`);
    console.log(`updating id ${customer_id}`);

    setCustomerName(customerName);
    setCustomerPhone(customerPhone);
    setCustomerID(customer_id);

    dispatch(GET(getMessageList, `conversations/${customer_id}/messages?limit=${limit}`));
  };

  const appendChat = (customer_id, { limit, before, after }) => {
    console.log(`Invoke appendChat()`);
    console.log(`updating id ${customer_id}`);

    dispatch(GET(appendMessageList, `conversations/${customer_id}/messages`, { params: { limit, before, after } }));
  };

  const loadMore = ({ before, after, limit }) => {
    console.log(`Invoke loadMore()`);
    // setMessageLimit(oldLimit => {return oldLimit += limit});
    // updateWindowChat(customerID, customerName, customerPhone, messageLimit);
    appendChat(customerID, { limit: limit ? limit : messageLimit, before, after });
  };

  const resetMessageLimit = () => {
    setMessageLimit(limit);
  };

  const updateRoomList = ({ callback } = {}) => {
    console.log('updateRoomList()', { callback });
    dispatch(
      POST(
        getRoomList,
        'conversations',
        {
          keyword: debouncedChatSearchOptions.keyword,
          agents: Object.keys(debouncedChatSearchOptions.agents),
          agentGroups: Object.keys(debouncedChatSearchOptions.agentGroups),
          includeNoHistory: debouncedChatSearchOptions.includeNoHistory,
          _sort: debouncedChatSearchOptions._sort,
        },
        {
          callback: response => {
            try {
              if (
                (debouncedChatSearchOptions.keyword !== '' ||
                  Object.keys(debouncedChatSearchOptions.agents).length > 0 ||
                  Object.keys(debouncedChatSearchOptions.agentGroups).length > 0) &&
                Array.isArray(response?.data?.data)
              ) {
                console.log('Memorize the customer IDs in filtered result.', { debouncedChatSearchOptions, response });
                filteredCustomerIds.current = {};

                for (const conversation of response.data.data) {
                  const customerId = conversation.customer_id;

                  filteredCustomerIds.current[customerId] = true;
                }

                console.log('filteredCustomerIds', filteredCustomerIds.current);
              } else {
                filteredCustomerIds.current = undefined;
              }

              if (typeof callback !== 'undefined') {
                callback(response);
              }
            } catch (error) {
              console.log({ error });
            }
          },
        }
      )
    );
  };

  const addCustomer = (new_phone, new_name, business_phone, { callback } = {}) => {
    // check is customer already existed?
    let existed = false;
    conversation.roomList &&
      conversation.roomList.length > 0 &&
      conversation.roomList.forEach((room, index) => {
        if (room.phone_number == new_phone) {
          // new customer already existed
          console.log(`new customer already existed: ${new_phone}`);
          updateWindowChat(
            conversation.roomList[index]?._id,
            conversation.roomList[index]?.profile_name,
            conversation.roomList[index]?.phone_number,
            limit
          );
          existed = true;
          return false;
        }
      });

    if (!existed) {
      console.log(`adding customer ${new_phone}`);
      let request_body = {
        phone_number: new_phone,
        profile: { name: new_name },
        wa_id: '',
        profile_name: new_name,
        agent_id: null,
        agent_name: null,
        action_user_id: authState.userID,
      };
      if (authState.userRole == 'Agent') {
        request_body = {
          ...request_body,
          agent_id: authState.userID,
          agent_name: authState.userName,
        };
      }
      apiGenerator({
        method: 'POST',
        path: `customers/create`,
        body: request_body,
      }).then(res => {
        console.log('POST customers/create', { res });

        // updateWindowChat(customerID, customerName, customerPhone)
        updateRoomList();

        let new_user = res?.data?.data?.docs?.[0];
        console.log({ new_user });

        updateWindowChat(new_user._id, new_user.profile_name, new_user.phone_number);

        if (typeof callback !== 'undefined') {
          callback(res);
        }
      });
    }
  };

  const addMessageToConversation = useCallback(({ customers, text, sender }) => {}, []);

  const markMessage = message_id => {
    console.log(`marking message ${message_id}`);
    socket.emit('mark-message', { message_id });
  };

  const sendMessage = async (recipient_id, text, file = undefined, replyToMessageId = undefined) => {
    timeTaken = new Date().getTime();
    console.log('on sendMessage', recipient_id, text, file, replyToMessageId);
    // addMessageToConversation({customer, text, sender: authState.userID})
    // recipient_id = "63871e3d8b05e65222fb1640"
    // Moved
    // console.log(`Sending Message to ${recipient_id}: ${text}`);
    // socket.emit('send-message', {recipient_id, text})
    // send msg with API

    let messageBody;

    if (file !== undefined) {
      // Has file to upload along with message
      messageBody = new FormData();

      messageBody.append('data', file);

      switch (file.type) {
        // Audio
        case 'audio/aac':
        case 'audio/mp4':
        case 'audio/mp3':
        case 'audio/mpeg':
        case 'audio/amr':
        case 'audio/ogg':
        case 'audio/ogg; codecs=opus':
          messageBody.append('type', 'audio');
          break;

        // Document
        case 'text/plain':
        case 'application/pdf':
        case 'application/vnd.ms-powerpoint':
        case 'application/msword':
        case 'application/vnd.ms-excel':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
          messageBody.append('type', 'document');
          messageBody.append('caption', text);
          break;

        // Image
        case 'image/jpeg':
        case 'image/png':
          messageBody.append('type', 'image');
          messageBody.append('caption', text);
          break;

        // Video
        case 'video/mp4':
        case 'video/3gp':
          messageBody.append('type', 'video');
          messageBody.append('caption', text);
          break;

        default:
          console.log({ error: 'Unsupported file type' });
          return;
      }

      messageBody.append('organization', 'raysion');
      messageBody.append('customer_id', recipient_id);
      messageBody.append('action_user_id', authState.userID);
    } else {
      // Message only
      messageBody = {
        type: 'text',
        organization: 'raysion',
        customer_id: recipient_id,
        msg: text,
        action_user_id: authState.userID,
      };
    }

    if (typeof replyToMessageId === 'string') {
      messageBody.replyToMessageId = replyToMessageId;
    }

    return new Promise(async (resolve, reject) => {
      apiGenerator({
        method: 'POST',
        path: `conversations/${recipient_id}/messages`,
        body: messageBody,
      })
        .then(res => {
          console.log(`conversations/${recipient_id}/messages Response`, res);
          console.log(`Sending Message to ${recipient_id}: ${text}`);
          // socket.emit('send-message', { recipient_id, text });

          return resolve(res);
        })
        .catch(error => {
          console.log(`conversations/${recipient_id}/messages Error`, error);

          return reject(error);
        });
    });
  };

  const sendTemplate = (recipient_id, template_id, components = undefined) => {
    console.log('sendTemplate()', { recipient_id, template_id, components })

    // let parameters = [];
    // console.log(params);
    // if (params) {
    //   for (const params_key in params) {
    //     parameters[params_key] = {
    //       type: 'body',
    //       text: params[params_key],
    //     };
    //   }
    // }

    apiGenerator({
      method: 'POST',
      path: `conversations/${recipient_id}/messages`,
      body: {
        type: 'template',
        organization: 'raysion',
        customer_id: recipient_id,
        message_template_id: template_id.replace('#', ''),
        action_user_id: authState.userID,
        components,
      },
    }).then(res => {
      console.log(res);
      updateWindowChat(customerID, customerName, customerPhone);
      updateRoomList();
    });
  };

  const sendReaction = (recipient_id, message_id, emoji) => {
    console.log(`Sending Message to ${recipient_id} ${message_id}: ${emoji}`);

    // send reaction with API

    apiGenerator({
      method: 'POST',
      path: `conversations/${recipient_id}/messages`,
      body: {
        type: 'reaction',
        organization: 'raysion',
        action_user_id: authState.userID,
        message_id: message_id,
        emoji: emoji,
      },
    }).then(res => {
      console.log(res);
      // updateWindowChat(customerID, customerName, customerPhone);
      // updateRoomList();
    });
  };

  const receiveMessage = useCallback(
    ({ recipient_id, text, messageSide }) => {
      console.log(`${customerID} receiving message from : ${recipient_id}, ${text.body}`);

      if (messageSide === 'from_customer') playNotificationSound();
      // if(recipient_id == customerID){
      //     // receive message from current opened customer chat window
      //     updateWindowChat(recipient_id, customerName, customerPhone);
      // }else{
      //     updateRoomList()
      // }
      // Only refresh the current active chat if the new message is from the same customer
      if (recipient_id == customerID) {
        updateWindowChat(recipient_id, customerName, customerPhone);
      }

      // ! Deprecated, conversation list should be updated by "conversation-update" socket event now.
      // updateRoomList();

      if (timeTaken != null) {
        console.log(`[Socket Receive Message]receiveMessage time taken ${new Date().getTime() - timeTaken}`);
        timeTaken = null;
      }
    },
    [customerID, customerName, customerPhone]
  );

  const conversationUpdate = useCallback(
    socketPayload => {
      console.log(`conversationUpdate()`, {
        socketPayload,
        filteredCustomerIds: filteredCustomerIds.current,
        conversationRoomList: conversation.roomList,
      });

      if (socketPayload.conversation._id === undefined) {
        console.log('The conversation is missing _id.');
        return;
      }

      if (
        typeof filteredCustomerIds?.current === 'object' &&
        typeof filteredCustomerIds?.current?.[socketPayload.conversation._id] === 'undefined'
      ) {
        console.log(
          `Skipping the conversation ${socketPayload.conversation._id} due to not being found in filtered customer IDs.`
        );
        return;
      }

      const newRoomList = [...conversation.roomList];

      // Reorder the conversation list according to updated conversation.
      let itemIndex = newRoomList.findIndex(({ _id }) => {
        return _id === socketPayload.conversation._id;
      });

      if (itemIndex >= 0) {
        // Item exists in the array, remove it
        newRoomList.splice(itemIndex, 1);
      }

      // Find the correct position to insert the updated item
      let insertIndex = newRoomList.findIndex(item => item.last_msg_time < socketPayload.conversation.last_msg_time);
      if (insertIndex === -1) {
        // If all items in the array have higher t value, insert at the end
        insertIndex = newRoomList.length;
      }

      // Insert the updated item at the correct position
      newRoomList.splice(insertIndex, 0, socketPayload.conversation);

      console.log({ conversationRoomList: newRoomList });

      dispatch(setRoomList({ newRoomList }));
    },
    [dispatch, conversation.roomList]
  );

  // const handleUpdateChatList = useCallback(() => {
  //   console.log(`on socket update-chat-list`, {
  //     customerID,
  //     customerName,
  //     customerPhone,
  //   });
  //   if (customerID && customerName && customerPhone) {
  //     updateWindowChat(customerID, customerName, customerPhone);
  //   }
  // }, [customerID, customerName, customerPhone]);

  const updateAgentList = () => {
    apiGenerator({
      method: 'GET',
      path: `users/agent`,
      body: {},
    }).then(res => {
      setAgentList(res.data.params);
    });
  };

  const updateAgentGroupList = () => {
    apiGenerator({
      method: 'GET',
      path: `user_groups`,
      body: {},
    }).then(res => {
      if (res?.data?.data) setAgentGroupList(res.data.data);
    });
  };

  const updateCustomerAgent = (selected_customer_id, selectedAgents, selectedAgentGroups) => {
    apiGenerator({
      method: 'PATCH',
      path: `customers/${selected_customer_id}`,
      body: {
        //   agent_id: selectedAgent._id,
        //   agent_name: selectedAgent.name,
        agents: selectedAgents,
        agent_groups: selectedAgentGroups,
        action_user_id: authState.userID,
      },
    }).then(res => {
      updateRoomList();
    });
  };

  const updateCustomerMarkedMessageList = () => {
    console.log(`updateCustomerMarkedMessageList...`);
    return apiGenerator({
      method: 'POST',
      path: `conversations/${customerID}/markedMessage`,
      body: {},
    });
  };

  const markMessageAsRead = customer_id => {
    console.log(`on markMessageAsRead... ${customer_id}`);

    if (document.visibilityState === 'hidden') {
      console.log('User is not focusing on window. "read-message" socket event will not be triggered.');

      return;
    }

    socket.emit('read-message', { customer_id });
  };

  const getSearchMessageList = async (search_str, search_date) => {
    console.log(`getting conversation message list...`);

    if (customerID === undefined) {
      console.log('No customer is selected yet.');
      return;
    }

    return await apiGenerator({
      method: 'POST',
      path: `conversations/${customerID}/searchMessage`,
      body: {
        search_str: search_str,
        search_date: search_date,
      },
    });
  };

  // * Check if free-form message is allowed.
  // ^ Function name should be changed in the future for better clarity.
  // Return false if not expired.

  // Expiration cases to handle: (Cases that cannot send free-form message)
  // - (Cannot free-text) No customer respond. (Conversation will opened)
  // - (Can free-text) Customer sent message first, no agent respond. (Conversation will not opened, customer service window opened)
  // - (Cannot free-text) Customer sent message first, after 24 hours, agent responded. (Customer service window is closed. Conversation will not opened, message won't deliver)
  // - (Cannot free-text) Latest conversation is expired.
  // - (Cannot free-text) No chat history.
  const checkCurrentExpiration = () => {
    console.log(`checking current converation ${customerID} expiry time...`, {
      messageList: conversation?.messageList,
    });

    const currentTs = new Date().getTime();

    if (!Array.isArray(conversation?.messageList) || conversation.messageList.length === 0) {
      // No chat history.
      console.log(`No chat history.`);
      return true;
    }

    const last_customer_message_time = conversation.messageList[0]?.customer_customer_message?.last_delivered_time;

    if (typeof last_customer_message_time !== 'string') {
      console.log(`No customer message history`);
      return true;
    }

    const last_customer_message_ts = new Date(last_customer_message_time).getTime();
    const customer_service_window_expiration_ts = last_customer_message_ts + 24 * 60 * 60 * 1000; // Add 24 hours.

    if (currentTs > customer_service_window_expiration_ts) {
      console.log(`Outside custoemr service window.`);
      return true;
    }

    return false;
    

    const has_local_conversation = conversation.messageList[0]?.conversation?.length === 1;
    const expiry_time = conversation.messageList[0].expiration_timestamp * 1000; // unixtime stamp
    const conversation_id = conversation.messageList[0]?.conversation_id; // unixtime stamp
    const conversation_type = conversation.messageList[0]?.conversation_type; // unixtime stamp
    const started =
      typeof conversation.messageList[0].started === 'boolean' ? conversation.messageList[0].started : false; // Boolean
    const last_message_receive_timestamp = new Date(conversation.messageList[0].timestamp).getTime();
    const is_customer_send = conversation.messageList[0].customer_phone === conversation.messageList[0].from;
    const initiated_by =
      typeof conversation_type === 'undefined' || conversation_type === 'service'
        ? 'user_initiated'
        : 'business_initiated';
    const customer_service_window_expiry_time =
      conversation.messageList[0].customer_service_window_expiration_timestamp * 1000; // unixtime stamp

    console.log('Conversation expiration checking parameters', {
      currentTs,
      has_local_conversation,
      expiry_time,
      conversation_type,
      started,
      last_message_receive_timestamp,
      is_customer_send,
      initiated_by,
      customer_service_window_expiry_time,
    });

    if (!has_local_conversation) {
      console.log(`No local conversation.`);
      return true;
    }

    if (typeof expiry_time !== 'undefined' && currentTs > expiry_time) {
      // Latest conversation is expired.
      console.log(`Latest conversation is expired.`);
      return true;
    }

    // if (typeof conversation_id !== 'undefined' && started === false) {
    //   // No customer respond. (Conversation will opened)
    //   console.log(`No customer respond. (Conversation will opened)`);
    //   return true;
    // }

    if (
      typeof customer_service_window_expiry_time !== 'undefined' &&
      currentTs > customer_service_window_expiry_time &&
      is_customer_send &&
      typeof last_message_receive_timestamp !== 'undefined' &&
      currentTs > new Date(last_message_receive_timestamp + 86400000).getTime() // 86400000 equals to 24 hours.
    ) {
      // Customer sent message first, after 24 hours, agent responded. (Customer service window is closed. Conversation will not opened, message won't deliver)
      console.log(
        `Customer sent message first, after 24 hours, agent responded. (Customer service window is closed. Conversation will not opened, message won't deliver)`
      );
      return true;
    }

    // ! Deprecated.
    // if((last_message_receive_timestamp > expiry_time || expiry_time == undefined) && is_customer_send){
    //     // Is not expired.
    //     // Conditions:
    //     // 1)
    //     console.log('expire case 1');
    //     return false
    // }

    // if (conversation_type === 'business_initiated' && started !== true) {
    //     // Is expired.
    //     // Conditions:
    //     // 1) Conversation is business initiated.; AND
    //     // 2) Conversation is not started (Implies no customer response)
    //     console.log('business-initiated conversation is not yet started');
    //     console.log('expire case 2');
    //     return true;
    // }

    // // const last_msg_today_time_diff = Math.abs(dayjs(conversation.messageList[0].expiration_timestamp).diff(currentTs, 'second'));
    // else if(currentTs > expiry_time || expiry_time == undefined){
    //     // Is expired.
    //     // Conditions:
    //     // 1) No conversation. (expiry_teim == undefined); OR
    //     // 2) Current time is greater than expiry time.
    //     console.log('expire case 3');
    //     return true
    //  }else{
    //     // Is not expired.
    //     console.log('expire case 4');
    //     return false
    // }

    return false;
  };

  const updateCustomerIsBlocked = (selected_customer_id, is_blocked) => {
    apiGenerator({
      method: 'PATCH',
      path: `customers/${selected_customer_id}/isBlocked`,
      body: {
        is_blocked,
      },
    }).then(res => {
      updateRoomList();
    });
  };

  const updateCustomerIsArchived = (selected_customer_id, is_archived) => {
    apiGenerator({
      method: 'PATCH',
      path: `customers/${selected_customer_id}/isArchived`,
      body: {
        is_archived,
      },
    }).then(res => {
      updateRoomList();
    });
  };

  const value = {
    sendMessage: sendMessage,
    updateWindowChat: updateWindowChat,
    receiveMessage: receiveMessage,
    conversationUpdate: conversationUpdate,
    sendTemplate: sendTemplate,
    sendReaction: sendReaction,
    addCustomer: addCustomer,
    loadMore: loadMore,
    getMessageLimit: getMessageLimit,
    resetMessageLimit: resetMessageLimit,
    setMessageLimit: setMessageLimit,
    updateAgentList: updateAgentList,
    updateAgentGroupList: updateAgentGroupList,
    updateRoomList: updateRoomList,
    updateCustomerAgent: updateCustomerAgent,
    markMessageAsRead: markMessageAsRead,
    markMessage: markMessage,
    updateCustomerMarkedMessageList: updateCustomerMarkedMessageList,
    getSearchMessageList: getSearchMessageList,
    checkCurrentExpiration: checkCurrentExpiration,
    updateCustomerIsBlocked,
    updateCustomerIsArchived,
    customerName: customerName,
    customerPhone: customerPhone,
    customerID: customerID,
    agentList: agentList,
    agentGroupList: agentGroupList,
  };

  // * Effect hook.
  useEffect(() => {
    console.log('debug authState.userID', authState.userID)
    if (authState.userID !== '') {
      dispatch(POST(checkSession,"users/check_session",{}));
    }
    // updateRoomList();
    // eslint-disable-next-line
  }, []);

  // For [socket] dependency socket listener
  useEffect(() => {
    console.log('On change socket listeners(1)', { socket });

    if (socket === undefined) {
      console.log('Socket client is not initialized.');
      return;
    }

    console.log('Adding listener to socket events.');

    socket.on('connect', function () {
      socket.emit('join-room');
    });
    socket.on('join-room-message', message => {
      console.log(message);
    });
    socket.on('room-brocast', message => {
      console.log(message);
    });

    return () => socket.off('receive-message');
  }, [socket]);

  // For [socket, customerID, customerName, customerPhone] dependency socket listener
  useEffect(() => {
    console.log('On change socket listeners(2)', { socket, customerID, customerName, customerPhone });

    if (socket === undefined) {
      console.log('Socket client is not initialized.');
      return;
    }

    console.log('Adding listener to socket events.');

    // socket.on('receive-message', receiveMessage);

    const onNewMessage = ({ message, action }) => {
      console.log(`Socket on \`new-message\``, { message, });

      const fromCustomer = message?.from === message?.customer_phone;

      console.log({ fromCustomer });

      if (fromCustomer && typeof action === 'undefined') {
        // Message is from customer and no action is stated. (Not emoji reaction/star message)
        playNotificationSound();
      }

      if (message?.customer_id === customerID) {
        console.log('current message list', conversation.messageList);
        let newMessageList = [...conversation.messageList];

        // Check if the message already exists in the list
        const existingIndex = newMessageList.findIndex((msg) => msg._id === message._id);
        if (existingIndex !== -1) {
          // Replace the existing message
          newMessageList[existingIndex] = message;
        } else if (typeof action === 'undefined') {
          // `action` = new message. Attach the message to the message list.
          // Traverse the list to find the correct position
          let inserted = false;
          for (let i = 0; i <= newMessageList.length; i++) {
            if (i === newMessageList.length || newMessageList[i]._id < message._id) {
              // Insert the new message at the correct position or at the end
              newMessageList.splice(i, 0, message);
              inserted = true;
              break;
            }
          }
        }

        dispatch(setMessageList(newMessageList));
      }
    };

    socket.on('new-message', onNewMessage);

    // socket.on('update-chat-list', handleUpdateChatList);

    return () => {
      // socket.off('receive-message', receiveMessage);
      socket.off('new-message', onNewMessage);
      // socket.off('update-chat-list', handleUpdateChatList);
    };
  }, [socket, customerID, customerName, customerPhone, conversation.messageList]);

  useEffect(() => {
    console.log('On change socket listeners(3)', { conversationRoomList: conversation.roomList });

    if (socket === undefined) {
      console.log('Socket client is not initialized.');
      return;
    }

    console.log('Adding listener to socket events.');

    socket.on('conversation-update', conversationUpdate);

    return () => {
      socket.off('conversation-update', conversationUpdate);
    };
  }, [socket, conversationUpdate, conversation.roomList]);

  return <ConversationContext.Provider value={value}>{children}</ConversationContext.Provider>;
}
export default ConversationContext;
