import React from 'react';
import Container from 'react-bootstrap/Container';
import { withTranslation } from 'react-i18next';

const Dashboard = props => {
  const { t } = props;


  return (
    <>
      <Container>
        <div className="d-flex align-items-center mt-3 mb-3">
          <div>
            <h1 className="page-header mb-0">{t('welcome_to_chatduck')}</h1>
          </div>
        </div>
      </Container>
    </>
  );
};

export default withTranslation()(Dashboard);
